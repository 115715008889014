import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {graphql, useStaticQuery} from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Img from "gatsby-image";

const NotFoundPage = () => {
    const data = useStaticQuery(graphql`
        query {
            introBG: file(relativePath: { eq: "backgrounds/cdwv-bg-intro-alt.svg" }) {
                publicURL
            },
            notFoundPageIntroImage: file(relativePath: { eq: "404/404-intro-image.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1200) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    let serviceSectionStyle = {
        backgroundImage: `url(${data.introBG.publicURL})`
    }

    return (
        <Layout>
            <SEO title="404: Not found"/>
            <section className="cw-container-fluid" style={serviceSectionStyle}>
                <div className="intro intro--404">
                    <div className="intro__column">
                        <Img className="photo" fluid={data.notFoundPageIntroImage.childImageSharp.fluid} />
                    </div>
                    <div className="intro__column">
                        <div className="intro-cta">
                            <h1 className="intro-cta__header">Something went wrong!</h1>
                            <p className="intro-cta__description">The page you thought exists does in fact exist but you typed the wrong URL on accident...</p>
                            <div className="intro-cta__buttons">
                                <AniLink duration={.5} fade to="/" className="btn">Go back to Homepage</AniLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>

    )
}

export default NotFoundPage